import * as React from "react"
import { graphql, Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import backToProjects from '../../../images/back-to-projects.svg'
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import kosuyoluPenthouse1 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-1.jpg"
import kosuyoluPenthouse2 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-2.jpg"
import kosuyoluPenthouse3 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-3.jpg"
import kosuyoluPenthouse4 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-4.jpg"
import kosuyoluPenthouse5 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-5.jpg"
import kosuyoluPenthouse6 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-6.jpg"
import kosuyoluPenthouse7 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-7.jpg"
import kosuyoluPenthouse8 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-8.jpg"
import kosuyoluPenthouse9 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-9.jpg"
import kosuyoluPenthouse10 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-10.jpg"
import kosuyoluPenthouse11 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-11.jpg"
import kosuyoluPenthouse12 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-12.jpg"
import kosuyoluPenthouse13 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-13.jpg"
import kosuyoluPenthouse14 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-14.jpg"
import kosuyoluPenthouse15 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-15.jpg"
import kosuyoluPenthouse16 from "../../../images/kosuyolu-penthouse/kosuyolu-penthouse-16.jpg"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const KosuyoluPenthouse = () => {
    const { t } = useTranslation();
    const options = {
        buttons: {
            showFullscreenButton: false,
            showDownloadButton: false,
            showThumbnailsButton: false,
            showAutoplayButton: false,
        }
    }
    return (
        <Layout>
            <Seo title="Koşuyolu Penthouse 1" />
            <div className="mt-40 relative flex items-center justify-center md:mt-28">
                <div className="max-w-full overflow-hidden wrapper">
                    <div className="inline-block overflow-hidden whitespace-nowrap animate-marquee">
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">KOŞUYOLU PENTHOUSE 1</p>
                        <p className="inline-block font-thin leading-none cursor-default font-third text-loop xl:text-9xl md:text-7xl text-default opacity-[.15] md:mb-0">KOŞUYOLU PENTHOUSE 1</p>
                    </div>
                </div>
                <h1 className="mb-0 font-first text-default text-5xl font-semibold absolute m-auto uppercase md:text-3xl md:-mt-1 -mt-10">Koşuyolu Penthouse 1</h1>
            </div>
            <div className="w-full">
                <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                        <div className="w-full flex items-center justify-between flex-wrap md:mt-10">
                            <img src={kosuyoluPenthouse1} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse2} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse3} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse4} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse5} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse6} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse7} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse8} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse9} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse10} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse11} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse12} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse13} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse14} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse15} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                            <img src={kosuyoluPenthouse16} alt="" className="w-[31%] cursor-pointer md:w-[48%] mb-16 md:mb-4" />
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </div>
            <Link to="../" className="font-first text-default text-xl bg-slate-600/10 pr-3 flex items-center w-fit mt-24 ml-16 hover:ml-10 transition-all duration-300 rounded-3xl md:mt-10 md:mx-auto">
                <img src={backToProjects} alt="Projelere Dön" className="mr-3" />
                {t("GeriDon")}
            </Link>
        </Layout>
    )
}

export default KosuyoluPenthouse;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;